<template>
  <div>
    <v-expansion-panels class="mb-4">
      <v-expansion-panel v-for="(rarity, i) in rarities" :key="i">
        <v-expansion-panel-header color="grey600">
          <span v-if="rarity.name">
            <v-icon :color="rarity.color" class="text-body-1 mb-1 mr-1">
              mdi-circle
            </v-icon>
            <span class="font-weight-semibold" v-text="rarity.name" />
          </span>
          <span v-else class="text--disabled">Untitled</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey600">
          <v-form class="mb-4" @submit.prevent>
            <v-row>
              <v-col cols="12" md="4">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Name</label>
                  <v-text-field
                    v-model="rarity.name" filled dense color="primary500"
                    placeholder="Common"
                  />
                </fieldset>
              </v-col>

              <v-col cols="12" md="4">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Color</label>
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y bottom
                    min-width="auto"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="rarity.color"
                        filled dense color="primary500"
                        placeholder="Color"
                        hide-details
                      >
                        <template #prepend-inner>
                          <v-icon :color="rarity.color" class="text-body-1 mt-1">
                            mdi-circle
                          </v-icon>
                        </template>
                        <template #append>
                          <v-icon class="primary500--text link" v-on="on">
                            mdi-palette
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-color-picker
                      v-model="rarity.color"
                      mode="rgba"
                    />
                  </v-menu>
                </fieldset>
              </v-col>
            </v-row>
          </v-form>

          <div class="text-right">
            <v-btn
              color="red" class="text-none" small
              :disabled="loading"
              @click="onDelete(i)"
            >
              <v-icon small class="mr-1">
                mdi-trash
              </v-icon>
              Delete
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="text-right">
      <v-btn
        color="grey100" outlined class="text-none mr-2"
        small :disabled="loading" @click="onAdd"
      >
        <v-icon small class="mr-1">
          mdi-plus
        </v-icon>
        Add new
      </v-btn>

      <v-btn
        :color="hasChanges ? 'green--gradient' : 'grey200'" class="text-none" light
        small :loading="loading"
        @click="onSave"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
const DEFAULT_FORM = () => ({
  name: '',
  color: '',
})

export default {
  props: {
    loading: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rarities: [],
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.value) !== JSON.stringify(this.rarities)
    },
  },
  watch: {
    value: {
      handler(val) {
        this.rarities = structuredClone(val)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onAdd() {
      this.rarities.push(DEFAULT_FORM())
    },
    onDelete(i) {
      this.rarities.splice(i, 1)
    },
    onSave() {
      this.$emit('save', this.rarities)
    },
  },
}
</script>
